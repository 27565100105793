
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

import ContactDialog from '@/components/Contacts/ContactDialog.vue';
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Card from "primevue/card";
import Customer from "@/types/customer";
import DataTable from "primevue/datatable";
import Tooltip from "primevue/tooltip";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import Contact from "@/types/contact";
import Footer from "@/components/Footer.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ContactAutocomplete from "@/components/Autocompletes/Contact.vue";
import SplitButton from "primevue/splitbutton";

export default defineComponent({
  name: "Contacts",
  props: {
    index: Number,
  },
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    Checkbox,
    Dropdown,
    Footer,
    LoadingSpinner,
    Card,
    ContactAutocomplete,
    SplitButton,
    ContactDialog,
  },
  directives: {
    tooltip: Tooltip,
  },
  created() {
    const cust = this.getCustomer(this.index) as Customer;
    const is_cust_contacts_empty = cust.contact_id_items?.length != 0;
    this.contacts = this.getContacts(this.index);

    if(!this.contacts && is_cust_contacts_empty) {
      this.loadContacts();
    }
  },
  computed: {
    ...mapState(["customerInquiry", "session"]),
    ...mapGetters({
      getCustomer: "customerInquiry/getCustomer",
      getContacts: "customerInquiry/getContacts",
      getClient: "session/getClient",
      getContactTypes: "customerInquiry/getContactTypes",
      getCustomerMap: "customerInquiry/getCustomerMap"
    }),
    contactTypes(): any {
      return this.getContactTypes;
    },
  },
  data() {
    return {
      contacts: {} as { contact_items: Array<Contact> },
      selectedContacts: null,
      filters: {},
      loading: false,
      isSubmitting: false,
      isNewContact: false,
      contactDialog: false,
      deletecontactDialog: false,
      existingContactDialog: false,
      resetPwdDialog: false,
      submitted: false,
      isAddingField: false,
      contactsInfoToEdit: { contact_id: "" } as Contact | Record<string, unknown>,
      contactsinfo: { contact_id: "" } as Contact | Record<string, unknown>,
      items: [
        {
          label: "Add Existing",
          icon: "pi pi-search",
          command: () => {
            this.addExistingContact();
          },
        },
      ],
      existingContact: {} as any,
    };
  },
  methods: {
    ...mapActions({
      fetchContacts: "customerInquiry/getContacts",
      addNotification: "notification/add",
      addContact: "customerInquiry/addContact",
      updateContactFields: "customerInquiry/updateContactFields",
      updateCustomer: "customerInquiry/updateCustomer",
      resetContactPwd: "customerInquiry/resetContactPwd"
    }),
    validEmailAddress(contact: Contact) {
      return contact?.email_address_items && contact.email_address_items.length > 0;
    },
    handleCloseContactDialog():void {
      this.contactDialog = false;
    },
    handleContactSelected(contact: any) {
      this.existingContact = {contact_id: contact.contact_id}
    },
    createnewContact() {
      this.isNewContact = true;
      this.contactDialog = true;
    },
    addExistingContact() {
      this.existingContactDialog = true;
      /* ADD LOGIC FOR DISPLAYING EXISTING CONTACTS HERE */
    },
    editcontact(contactsinfo: any) {
      this.contactsInfoToEdit = JSON.parse(JSON.stringify(contactsinfo));
      this.isNewContact = false;
      this.contactDialog = true;
    },
    hideDialog() {
      this.contactDialog = false;
      this.submitted = false;
      this.isNewContact = false;
    },
    confirmDeletecontact(contactsinfo: any) {
      this.contactsinfo = contactsinfo;
      this.deletecontactDialog = true;
    },
    confirmResetPwd(contactsinfo: any) {
      this.contactsinfo = contactsinfo;
      this.resetPwdDialog = true;
    },
    saveExistingContact() {
      this.existingContactDialog = false
      const custId = this.getCustomer(this.index).cust_id;
      const map = this.getCustomerMap;
      const ind = this.index || 0;
      let contacts: Customer["contact_id_items"] = map[ind].customer.contact_id_items;
      const oldCust = {
        cust_id: custId,
        contact_id_items: contacts,
      };
      let newContacts = JSON.parse(JSON.stringify(contacts))
      newContacts.push(this.existingContact)
      const newCust = {
        cust_id: custId,
        contact_id_items: newContacts,
      }
      this.updateCustomer({
          custId: this.getCustomer(this.index).cust_id,
          oldCust,
          newCust,
          Client: this.getClient,
        })
        .then(() => {
          this.updateContactFields({
            action: "NEW",
            contacts: this.contacts,
            contactIdItems: { contact_id: this.existingContact.contact_id },
            index: this.index,
          });
          this.loading = true;
          this.fetchContacts({
            Client: this.getClient,
            index: this.index,
          })
          .then((response) => {
            this.contacts = this.getContacts(
              this.index
            );
          })
          .finally(() => {
            this.loading = false;
            });
          this.existingContact = {}
        });
    },
    deletecontact() {
      this.deletecontactDialog = false;
      const custId = this.getCustomer(this.index).cust_id;
      const map = this.getCustomerMap;
      const ind = this.index || 0;
      let contacts: Customer["contact_id_items"] = map[ind].customer.contact_id_items;
      const oldCust = {
        cust_id: custId,
        contact_id_items: contacts,
      };
      const newContacts = contacts.filter((item) => {
        return item.contact_id != this.contactsinfo.contact_id;
      });
      const newCust = {
        cust_id: custId,
        contact_id_items: newContacts,
      };
      this.updateCustomer({
          custId: this.getCustomer(this.index).cust_id,
          oldCust,
          newCust,
          Client: this.getClient,
        })
        .then(() => {
          this.contacts.contact_items = this.contacts.contact_items.filter((item) => {
            return item.contact_id != this.contactsinfo.contact_id;
          });
          this.updateContactFields({
            action: "DELETE",
            contacts: this.contacts,
            contactIdItems: { contact_id: this.contactsinfo.contact_id },
            index: this.index,
          });
        });
    },
    resetPwd() {
      this.resetPwdDialog = false;
      //Dispatch Reset Password
      this.resetContactPwd({
        Id: this.contactsinfo.contact_id,
        Caller: "CLIENT",
        Client: this.getClient,
      });
    },
    loadContacts() {
      this.loading = true;
      this.fetchContacts({
        Client: this.getClient,
        index: this.index,
      })
      .then((response) => {
        this.contacts = this.getContacts(
          this.index
        );
      })
      .finally(() => {
        this.loading = false;
      });
    },
  },
});
